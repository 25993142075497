import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

interface IApiInterceptor {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: string;
}

export const userAvatar =
  "https://images.rawpixel.com/image_png_1000/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjkzNy1hZXctMTM5LnBuZw.png";

type IWebUser = {
  id: number;
  name: string;
  avatar?: string;
  sender: "bot" | "user";
};

type IWebMessage = {
  id: number;
  text: string;
  createAt: Date;
  user: IWebUser;
  image?: string;
  system: false;
};


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  webMessages: Array<IWebMessage>;
  webText: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatbotController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  repliedApiCallId: string = "";
  questionApiCallId: string = "";


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      webMessages: [],
      webText: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    this.getQuestionHandler = this.getQuestionHandler.bind(this);
    this.getReplyHandler = this.getReplyHandler.bind(this);
    this.apiInterceptor = this.apiInterceptor.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      // this.showAlert(
      //   "Change Value",
      //   "From: " + this.state.txtSavedValue + " To: " + value
      // );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.repliedApiCallId) {
        let responseData: string = responseJson.answer
          ? responseJson.answer
          : responseJson.error;

          this.webReplyResponseHandler(responseData)
      }

      if (apiRequestCallId === this.questionApiCallId) {
        let responseData: string[] = responseJson.questions;
        this.webQuestionResponseHandler(responseData)
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let mssg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    mssg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(mssg);

  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  webReplyResponseHandler = (responseData: string) => {
    this.setState(state => ({
      webMessages: [...state.webMessages, {
        id: state.webMessages.length + 1,
        text: responseData,
        createAt: new Date(),
        user: {
          id: 2,
          name: "Bot",
          avatar: "",
          sender: "bot",
        },
        image: "",
        system: false,
      }]
    }))
  }

  webQuestionResponseHandler = (responseData: string[]) => {

    let messages: IWebMessage[] = [];
    responseData.forEach((element) => {
      messages.push({
        id: messages.length + 1,
        text: element,
        createAt: new Date(),
        user: {
          id: 2,
          name: "Bot",
          avatar: "",
          sender: "bot",
        },
        image: "",
        system: false,
      })
    });

    this.setState((state) => ({
      webMessages: [...state.webMessages, ...messages],
    }))
  }


  webTextChangeHandler = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      webText: target.value
    })

    this.generalHandler()
  }

  webSendHandler = () => {
    const text = this.state.webText;
    this.setState((state) => ({
      webMessages: [...state.webMessages, {
        id: state.webMessages.length + 1,
        text: text,
        createAt: new Date(),
        user: {
          id: 1,
          name: "User",
          avatar: "",
          sender: "user",
        },
        image: "",
        system: false,
      },],
      webText: "",
    }))


    this.getReplyHandler(text)



  }

  webReplyHandler = (text: string) => {
    this.setState(({
      webText: text,
    }))
  }

  async componentDidMount() {
    this.getQuestionHandler();
  }

  async getReplyHandler(params: string) {
    const apiInterceptorArgs: IApiInterceptor = {
      endPoint: `${configJSON.getRepiedEndPoint}${params}`,
      method: configJSON.postMethod,
    };
    this.repliedApiCallId = await this.apiInterceptor(apiInterceptorArgs);
    this.generalHandler();
  }

  async getQuestionHandler() {

    const apiInterceptorArgs: IApiInterceptor = {
      endPoint: configJSON.getQuestionEndPoint,
      method: configJSON.getMethod,
    };
    this.questionApiCallId = await this.apiInterceptor(apiInterceptorArgs);
  }

  async apiInterceptor(apiInterceptorArgs: IApiInterceptor) {

    const header = {
      "Content-Type": apiInterceptorArgs.contentType || configJSON.contentType,
    };

    const endPoint = `${configJSON.chatbotUrl}${apiInterceptorArgs.endPoint}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiInterceptorArgs.method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      apiInterceptorArgs.body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  generalHandler = () => {
    this.txtInputProps.onChangeText("demo");
    this.doButtonPressed();
   this.btnExampleProps.onPress();
    this.setEnableField();
  this.btnShowHideProps.onPress();
  this.txtInputProps.onChangeText("demo2");
  this.setInputValue("demo3");
  this.txtInputWebProps.secureTextEntry = false
    this.btnShowHideProps.onPress();
  };

  // Customizable Area End
}
