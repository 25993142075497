Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "chatbot";
exports.labelBodyText = "chatbot Body";

exports.btnExampleTitle = "CLICK ME";
exports.postMethod = "POST";
exports.getMethod = "GET";
exports.contentType = "application/json";
exports.chatbotUrl = "bx_block_chatbot6/chatbot_messages/";
exports.getQuestionEndPoint = "questions";
exports.getRepiedEndPoint =  "reply?question=";
// Customizable Area End