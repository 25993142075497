import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
const botAvatar =
  "https://media.istockphoto.com/id/951223744/photo/human-wireframe-model-with-connection-lines-on-blue-background-artificial-intelligence-in.webp?b=1&s=170667a&w=0&k=20&c=r1lo0BgdeMsgy5xhyMFD0egHz2TWXZ0Dr5RUF2OWjD0=";

const userAvatar =
  "https://images.unsplash.com/photo-1527980965255-d3b416303d12?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

// Customizable Area End

import ChatbotController, {
  Props,
  configJSON,
} from "./ChatbotController";

export default class Chatbot extends ChatbotController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div
        style={
          styles.container
        }
      >
        <div
          style={{ ...styles.chatWrapper, position: "absolute", }}
        >
          <div
            style={{ ...styles.messagesBox, flexDirection: "column", overflowY: "scroll" }}
          >
            {this.state.webMessages.map((message) => {
              return (
                <div
                  data-testId="message"
                  key={message.id}
                  style={{
                    ...styles.messageStyle, alignSelf: message.user.sender === "bot" ? "start" : "end",
                    flexDirection: message.user.sender === "bot" ? "row" : "row-reverse"
                  }}
                >
                  <div
                    style={{
                      ...styles.imageStyle,
                      marginLeft: message.user.sender === "bot" ? "0px" : "5px",
                      marginRight: message.user.sender === "bot" ? "5px" : "0px",
                    }}
                  >
                    <img
                      src={message.user.sender === "bot" ? botAvatar : userAvatar}
                      alt=""
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                  <div
                    data-testId="replyButton"
                    onClick={() => this.webReplyHandler(message.text)}
                    style={
                      styles.replyButtonStyle
                    }
                  >
                    <p
                      data-testId={`message-text-${message.id}`}
                      style={{
                        boxSizing: "border-box",
                        margin: 0,
                        padding: "5px 0"
                      }}>{message.text}</p>

                  </div>
                </div>
              );
            })}
          </div>
          <div
            style={styles.mainInputDiv}
          >
            <div
              style={styles.inputDivStyle}
            >
              <input
                type="text"
                data-testId="textInputEle"
                style={styles.inputStyle}
                value={this.state.webText}
                onChange={this.webTextChangeHandler}
                placeholder="type here..."
              />
              <button
                data-testId="sendButtonEle"
                onClick={this.webSendHandler}
                style={styles.buttonStyle}
              >
                send
              </button>
            </div>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  container: {
    width: "100%",
    minHeight: "100vh",
  },
  messageStyle: {
    padding: "0 10px",
    display: "flex",
    marginBottom: "8px",
  },

  messagesBox: {
    display: "flex",
    height: "60vh"
  },

  chatWrapper: {
    width: "80%",
    margin: "auto",

    left: "50%",
    transform: "translate(-50%, 0)",
    bottom: "20px",
  },

  imageStyle: {
    overflow: "hidden",
    borderRadius: "50px",
    width: 30,
    height: 30,
    alignSelf: "end"
  },

  replyButtonStyle: {
    maxWidth: "300px",
    padding: "8px 12px",
    border: "1px solid #ccc",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
  },

  inputStyle: {
    display: "inline-block",
    outline: "none",
    width: "90%",
    padding: "0 30px",
    height: "50px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },

  buttonStyle: {
    display: "inline-block",
    height: "50px",
    padding: "0px 30px",
    borderRadius: "5px",
    outline: "none",
    border: "1px solid blue",
    backgroundColor: "white",
    cursor: "pointer",
  },

  inputDivStyle: {
    width: "100%",
    marginTop: "30px",
    display: "flex",
    justifyContent: "space-around",
  },

  mainInputDiv: {
    padding: "0 20px",
  },
  child: {
    width: "100%",
    flex: 1,

  },
  subContainer: {
    height: 63,
    alignItems: "center",
    flexDirection: "row" as const,
    justifyContent: "space-between",
    paddingHorizontal: 10,
  },
  header: {
    flexDirection: "row" as const,
  },
  headerView: {
    flexDirection: "row" as const,
    marginLeft: 20,
    alignItems: "center",
  },
  chatBotTextStyle: {
    color: "black",
    marginLeft: 5,
    fontSize: 15,
  },
  headerSubView: {
    flexDirection: "row" as const,
    marginLeft: 20,
    alignItems: "center",
  },
  chatBotText: {
    color: "black",
    marginLeft: 5,
    fontSize: 15,
  },

};

// Customizable Area End
